<template>
  <div>
    <!-- <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,0,0)">
      导出试卷
    </div>
    <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,1,0)">
      导出试卷且显示题目来源
    </div>
    <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,0,3)">
      导出答案解析
    </div>
    <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,0,1)">
      导出完整版
    </div>
    <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,1,1)">
      导出完整版且显示题目来源
    </div> -->

    <span style="padding:0 20px 0 10px">
      <el-checkbox v-model="exportInfo.showTopic"
                   :disabled="!exportInfo.detail">题目</el-checkbox>
      <el-checkbox v-model="exportInfo.show_source">题目来源</el-checkbox>
      <el-checkbox v-model="exportInfo.detail"
                   @change="changeAnswer">答案解析</el-checkbox>
    </span>

    <div class="text_btn_primary1"
         @click="printWord">
      导出
    </div>

    <div class="text_btn_primary1"
         v-if="is_examine==1"
         @click="printStatic($route.query.edu_paper_id)">
      导出考查细目表
    </div>
  </div>
</template>

<script>
import { exportWord, exporExcel } from '@/api/paper.js'
export default {
  data () {
    return {
      is_examine: 0, //是否显示考查细目表
      exportInfo: {
        detail: 0,
        show_source: 0,
        showTopic: true
      }
    }
  },
  props: ['paperInfo'],

  methods: {
    changeAnswer (value) {
      if (!this.exportInfo.detail) {
        this.exportInfo.showTopic = true
      }
    },
    async printWord (edu_paper_id, show_source, detail, has_explain) {
      let params = {
        edu_paper_id: this.$route.query.edu_paper_id,
        detail: this.exportInfo.detail ? 1 : 0,
        show_source: this.exportInfo.show_source ? 1 : 0,
      }
      if (!this.exportInfo.showTopic) {
        params.detail = 3
      }
      // const data = await exportWord(edu_paper_id, detail, show_source, has_explain)
      exportWord(params).then((data) => {
        // 请求错误或者 blob 大小小于 1024B 时，返回的是错误信息
        if (data.status == 400 || data.data.size < 1024) {
          this.$notify({
            title: '下载失败',
            message: '请重试',
            type: 'error'
          });
        } else {
          const a = document.createElement("a")
          a.href = window.URL.createObjectURL(data.data)
          let paperName = this.paperInfo.paper_name.length === 0 ? this.paperInfo.edu_paper_id : this.paperInfo.paper_name;
          a.download = `${paperName}`+(this.exportInfo.detail ? "答案解析" : "")+`.docx`
          a.click()
        }
      })
    },
    async printStatic (edu_paper_id) {
      const data = await exporExcel(edu_paper_id)
      const a = document.createElement("a")
      a.href = window.URL.createObjectURL(data)
      a.download = `${this.paperInfo.paper_name}考查细目表.xlsx`
      a.click()
    }
  },
  mounted () {
    let user = window.localStorage.getItem("userInfo")
    if (user) {
      this.is_examine = JSON.parse(user).is_examine
    }
  }
}
</script>

<style scoped>
</style>
